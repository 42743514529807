.side-menu-elem {
  height: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.sidebar {
    width: 30px;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(1.5px);
    /* padding: 10px; */
    /* left: calc(50% - 45px); */
    overflow-x: hidden;
    transition: all 0.3s ease-out 0.3s;
    cursor: pointer;
    border-right: #18181852;
    border-width: 0.5px;
    border-style: groove;
    background-color: #4CAF50;
  }

  .secondary-sidebar > div {
    margin-top: 50px;
  }
  .sidebar::-webkit-scrollbar,
  .secondary-sidebar::-webkit-scrollbar,
  .mat-menu-content::-webkit-scrollbar {
      width: 5px; 
  }
  
  .sidebar::-webkit-scrollbar-track,
  .secondary-sidebar::-webkit-scrollbar-track,
  .mat-menu-content::-webkit-scrollbar-track {
      background: transparent; 
  }
  
  .sidebar::-webkit-scrollbar-thumb,
  .secondary-sidebar::-webkit-scrollbar-thumb,
  .mat-menu-content::-webkit-scrollbar-thumb {
      background: #888; 
      border-radius: 10px;
  }
  
  .sidebar::-webkit-scrollbar-thumb:hover,
  .secondary-sidebar::-webkit-scrollbar-thumb:hover,
  .mat-menu-content::-webkit-scrollbar-thumb:hover {
      background: #555; 
  }
  
  .sidebar p {
    font-size: 14px;
  }
  
  /* Top */
  .top {
    margin-top: 10px;
    width: 30px;
  }
  
  .bottom {
      width: 80px;
      /* margin-top: 80px; */
    }
  
  .dots {
    margin: 10px 8px 10px 0;
  }
  
  .dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #ff5c4c;
    margin: 0 5px;
  }
  
  .dot:nth-of-type(2) {
    background: #ffbf38;
  }
  
  .dot:nth-of-type(3) {
    background: #66e14f;
  }
  
  .download {
      cursor: pointer;
      color: #222;
      position: absolute;
      left: 10px;
      width: 100%;
      height: auto;
      transition: 0.5s linear;
    }
    
  .logo {
    font-size: 45px;
    cursor: pointer;
    color: #222;
    position: absolute;
    left: 10px;
    transition: 0.5s linear;
  }
  
  /* End Top */
  
  /* Menu */
  .menu {
    margin-top: 50px;
    height: 70%;
    display: flex;
    flex-direction: column;
  }
  
  .menu-item {
    position: relative;
    width: 100%;
    border-radius: 12px 0 0 12px;
    justify-content: center;
    align-items: center;
    flex: 1;
    display: flex;
    padding-top: 2%;
  }
  
  /* End Menu */
  
  /* Messages */
  .messages {
    width: 70px;
    border-radius: 12px;
    background: #fff;
    transition: inherit;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    position: relative;
  }
  
  .messages::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 25px;
    background: #0460fc;
    bottom: -17px;
    left: calc(50% - 25px);
    border-radius: 10px;
    transition: inherit;
    text-align: center;
  }
  
  .msg {
    margin: 4px 0 4px 16px;
    justify-content: flex-start;
  }
  
  .msg img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .msg p {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  
  /* End Messages */
  
  .hide {
    transform: translateX(250px);
    opacity: 0;
    transition: all 0.5s ease;
    transition-delay: var(--delay);
  }
  
  .text-icon {
      font-size: 14px;
      margin-left: 5px;
      padding: 5px;
      margin-top: 5px;
      border-style: groove;
      border-radius: 12%;
      border-color: #16161670;
      border-width: 0.5px;
  }
  
  .text-icon.active {
      /* Styles for active button */
      background-color: rgba(147, 10, 142, 0.384);
      color: white;
  }
  
  .skin-color-item.active {
      background-color: rgba(147, 10, 142, 0.384);
  }
  
  .french-tip-item.active {
      background-color: rgba(147, 10, 142, 0.384);
  }
  
  .icon {
    position: absolute;
    left: 5px;
    top: 10px;
    transition: 0.5s linear;
  }
  
  .menu-item:hover {
    background: rgba(255, 255, 255, 0.5);
    transition: 0.6s ease;
  }
  
  .menu-item.active {
      background: rgba(147, 10, 142, 0.384);
      transition: 0.6s ease;
    } 
  
  .color-picker {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
      gap: 10px;
      padding: 10px;
      max-width: 100%;
      overflow: auto;
    }
    
  .color-swatch {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
      border: 2px solid transparent; 
  }
    
  .color-swatch.selected {
      border-color: black; 
  }
  
  .nail-art-card {
      width: 90px;
      height: 100%;
      background: rgba(34, 34, 34, 0.8);
      color: white;
      backdrop-filter: blur(6px);
      justify-content: center;
      padding: 10px;
      border-radius: 0 12px 12px 0;
      display: grid;
      overflow-y: auto;
      transition: all 0.3s ease-out 0.3s;
  }
  
  .nail-art-card p {
    text-transform: capitalize;
  }
  
  .add-nail-art {
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
  }
  
  .plus-sign {
      font-size: 2em;
      color: #333;
  }
  
  .curr-nail-art-item-options {
      font-size: 60px;
      display: flex;
      background: rgba(255, 255, 255, 0.288);
      flex-direction: column;
      padding: 0 10px;
    }
  
  .curr-nail-art-item-adjust {
      display: flex;
      align-items: center;
  }
  
  .curr-nail-art-item-adjust img {
      width: auto; /* Or set a specific width */
      height: auto; /* Maintain aspect ratio */
      margin-right: 10px; /* Add some space between the image and the slider */
  }
  
  .curr-nail-art-item-adjust mat-slider {
      flex-grow: 1;
  }
  
  .curr-nail-art-item-container {
      position: relative;
    }
  
  .curr-nail-art-item {
    border-radius: 5px;
    border-width: 0.5px;
    border-style: groove;
    border-color: #18181852;
    aspect-ratio: 1/1;
    width: 30px;
    margin-left: 50%; 
    transform: translate(-50%);
    padding: 1px;
  }
  
    .base-color-item.active {
      background-color: #e784db8b;
    }
  
    .edit-icon {
      position: absolute; 
      bottom: 0px; /* Distance from the bottom of the .curr-nail-art-item */
      right: 20%; /* Distance from the right of the .curr-nail-art-item */
      width: 16px; 
      height: 16px; 
    }
  
   .nail-art-icons {
      border-radius: 5px;
      border-width: 0.5px;
      border-style: groove;
      border-color: #18181852;
      aspect-ratio: 1/1;
      width: 30px;
      margin-left: 50%; 
      transform: translate(-50%);
      padding: 1px;
    }
  
    .curr-nail-art-item:hover, .nail-art-icons:hover {
      background-color: #e784db8b;
    }
  
  .done-nail-art-btn, .delete-nail-art-btn {
      flex-grow: 1; 
      display: flex;
      height: 25px;
      border-radius: 0.375rem;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1; /* This will make the content wrapper take the remaining space beside the menu */
  }
  
  #mycanvas {
    width: 100%;
    height: 100%;  
      /* width: 800px; 
      height: 500px; */
    background:radial-gradient(#9f2e79, #242631);
      /* margin-top: 30px; */
  }
  
  .touch-pan-x {
    touch-action: pan-x;
  }
  
  ::ng-deep .mat-tab-header {
    overflow-x: scroll !important;
  }

  
  .panel-content {
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    max-height: 100%;
  }
  
  .panel-content div div {
    justify-content: center;
    align-items: center;
  }
  
  .matTabItem {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2%;
  }
  
  .red-icon {
    color: red !important;
  }
  
  .green-icon {
    color: rgb(0, 152, 23) !important;
  }
  
  ::ng-deep .mat-select-panel::-webkit-scrollbar {
    width: 1px;
  }
  
  .mat-button-toggle {
    font-family: 'OpenSans';
  }
  
  .mat-button {
    font-family: 'OpenSans';
  }
/*   
  @font-face {
    font-family: "OpenSans";
    src: url(/assets/fonts/OpenSans.ttf) format("truetype");
  } */

  mat-slider {
      width: 100px;
    }
  
  label-container {
      display: flex;
      justify-content: space-between;
      margin: 20px 10px 0;
      max-width: 284px;
  }
  
  .example-container {
      width: 500px;
      height: 600px;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 5%;
    }
    
    .example-sidenav-content {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
    
    .designSideNav {
      padding: 4%;
      border-radius: 5%;
      width: auto;
      background-color: rgb(250, 198, 239);
      font-size: 15px;
    }
  
    .french-radio-group {
      display: flex;
      flex-direction: column;
      margin: 15px 0;
      align-items: flex-start;
    }
    
    .french-radio-button {
      margin: 5px;
    }
  
    .example-viewport {
      height: 200px;
      width: 200px;
      border: 1px solid black;
    }
    
    .example-item {
      height: 50px;
    }
  
    .horizontal-scroller {
      height: 100%;
      display: flex;
      align-items: center;
      overflow-y: hidden;
      overflow-x: auto;
  }
  
  .secondary-menu {
    font-size: 0.5rem;
    display: flex;
    background-color: rgb(255, 255, 255);
    border: 1px solid gray;
    border-bottom: none;
    overflow-x: scroll;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .primary-menu {
    display: flex;
    background-color: black;
    border: 1px solid gray;
    overflow-x: scroll;
    height: 50px;
  }
  
  .secondary-menu-item, .primary-menu-item {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    border: none;
    padding: 10px 0;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .secondary-menu-item {
    display: inline-block;
    color: #000000;
  }
  
  
  .live-text-group{
      display: flex;
      flex-direction: column;
  }
  
  .live-text {
    font-size: calc(0.5rem + 1vw);
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    padding: 10px;
    border-radius: 12px;
  }
  
  .checkout-button {
    position: absolute;
    font-size: 0.6rem;
    padding: 0.8em 1em; 
    background-color: rgba(255, 255, 255, 0.626);
    border: 0.5px black groove;
    height: 3rem;   
    right: 1rem;
    top: 1.5rem;
    color:rgba(27, 27, 28, 0.878);
    background-color: rgb(2, 91, 2);
  }
  
  .checkout-icon {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    vertical-align: middle;
  }
  
  .color-radio {
    display: none;
  }
  
  .color-label {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
  }
  
  .color-radio:checked + .color-label {
    border: 2px solid #000;
  }
  
  .color-radio:checked + .color-label svg {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .mat-menu-content {
    padding: 1px;
  }
  
  .open-side-menu {
    position: absolute;
    left: 5%;
    bottom: 15%;
    width: 4rem;
    height: 4rem;
    background-color: #4CAF50;
    border-radius: 50%;
    overflow: hidden;
    color: white;
    padding: 2.8rem;
    display: flex; /* Flexbox to align children */
    align-items: center; /* Align children vertically in the center */
    justify-content: center; /* Align children horizontally in the center */
    text-align: center; 
    flex-direction: column; 
    cursor: pointer; 
  }
  
  .open-side-menu img {
    width: 2rem; 
    height: auto;
    margin-bottom: 0.5rem;
  }
  
  .open-side-menu span {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
  
  .hidden {
    display: none;
  }
  
  @media (max-width: 768px) {
    .open-side-menu {
      left: 2%; /* Adjust as needed for mobile screens */
      bottom: 10%; /* Adjust as needed for mobile screens */
      /* Additional mobile-specific styles */
    }
  }
  