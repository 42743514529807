.content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1; /* This will make the content wrapper take the remaining space beside the menu */
  }

#mycanvas {
    /* width: 100%; */
    height: 100%;  
    background:radial-gradient(#72007b, rgb(255, 173, 226), rgb(255, 255, 255));
  }
  