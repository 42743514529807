.lds-default {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* display: inline-block;
    position: relative; */
    width: 180px;
    height: 180px;
  }
  
  .lds-default div {
    position: absolute;
    width: 9px;
    height: 9px;
    background: #fcf;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  
  .lds-default div:nth-child(odd) {
    animation-delay: calc(-0.1s * (var(--i) - 1));
  }
  
  .lds-default div:nth-child(even) {
    animation-delay: calc(-0.1s * var(--i));
  }
  
  .lds-default div {
    --angle: calc(360deg / 12 * (var(--i) - 1));
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(var(--angle)) translateY(-25%);
  }
  

  .lds-default div:nth-child(1) { --i: 1; top: 46.25%; left: 82.5%; }
  .lds-default div:nth-child(2) { --i: 2; top: 27.5%; left: 77.5%; }
  .lds-default div:nth-child(3) { --i: 3; top: 13.75%; left: 65%; }
  .lds-default div:nth-child(4) { --i: 4; top: 8.75%; left: 46.25%; }
  .lds-default div:nth-child(5) { --i: 5; top: 13.75%; left: 27.5%; }
  .lds-default div:nth-child(6) { --i: 6; top: 27.5%; left: 13.75%; }
  .lds-default div:nth-child(7) { --i: 7; top: 46.25%; left: 8.75%; }
  .lds-default div:nth-child(8) { --i: 8; top: 65%; left: 13.75%; }
  .lds-default div:nth-child(9) { --i: 9; top: 77.5%; left: 27.5%; }
  .lds-default div:nth-child(10) { --i: 10; top: 82.5%; left: 46.25%; }
  .lds-default div:nth-child(11) { --i: 11; top: 77.5%; left: 65%; }
  .lds-default div:nth-child(12) { --i: 12; top: 65%; left: 77.5%; }
  
  
  
@keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.5);
    }
  }
  
  /* for rotating hand */

  @keyframes scrollHorizontally {
    0% {
        transform: translateX(0%);
    }
    25% {
        transform: translateX(100%);
    }
    75% {
      transform: translateX(-200%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .svg-icon {
    width: 30px; /* Adjust the width as needed */
    height: 80px; /* Adjust the height as needed */
  }
  
  .rotate-icon {
    position: absolute;
    top: 70%;
    left: 50%;
    animation: scrollHorizontally 3.3s linear 1;
  }
